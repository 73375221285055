.pts-parallax {
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
}
.pts-parallax:before{
   background: url(../img/images/pattern.png);
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    opacity: 0.8;
}
/**
 * Suffix classes for make owner style for row and column. this will load automatic in setting form.
 * format: .pts-col.YOUR_SUFFIX_CLASS , .pts-row.YOUR_SUFFIX_CLASS;
 */

 /* widget load prestashop module **/
.widget-inner > [class*="col-"]{
  width: 100%!important;
  padding-left:0!important;
  padding-right: 0!important;
 }
/* column style */
.pts-col.default{

}

.pts-col.primary{

}

.pts-col.success{

}

.pts-col.info{

}

.pts-col.warning{

}

.pts-col.danger{

}

.pts-col.highlighted{

}

/* row style */
.pts-row.default{

}

.pts-row.primary{

}

.pts-row.success{

}

.pts-row.info{

}

.pts-row.warning{

}

.pts-row.danger{

}

.pts-row.highlighted{
  
}

.widget.default{

}
.widget.primary{

}
.widget.danger{

}
.widget.info{

}
.widget.success{

}
.widget.warning{

}
.widget.highlighted{

}

.widget.nopadding{

}
.widget.borderbox{

}
/**
 * Widget Styles
 */
 /* Separator style */
.widget-separator .w_text_separator {
  float: left;
  height: 2px;
  position: relative;
  width: 100%;
  background-color: #797C85;
}
.widget-separator .w_text_separator .text_separ {
  position: absolute;
  padding: 0 1px;
  width: 50px;
  top: -9px;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  font-size: 15px;
  font-family: monospace;
}
.widget-separator .separator_align_center {
  left: 0;
  right: 0;
}
.widget-separator .separator_align_left {
  left: 0;
}
.widget-separator .separator_align_right {
  right: 0;
}